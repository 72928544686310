import { BASE_URL } from 'constants/apiConstants';
import axios from 'axios';

const userAxios = axios.create({
  baseURL: BASE_URL + '/api/user',
});

export function setHeadersToken(token) {
  userAxios.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export function unSetHeadersToken() {
  userAxios.defaults.headers.common.Authorization = ``;
}

export async function updateGoalApi(body) {
  const { data } = await userAxios.put('/goal', { goal: body });
  return data;
}

export async function updateWeightApi(body) {
  const { data } = await userAxios.put('/weight', { weight: body });
  return data;
}

export async function updateSettingsApi(body) {
  const { data } = await userAxios.post(
    '/settings',

    {
      name: body.name,
      gender: body.gender,
      age: body.age,
      height: body.height,
      weight: body.weight,
      activity: body.activity,
      avatar: body.avatar,
    },
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );
  return data;
}

export async function getFoodIntake() {
  const { data } = await userAxios.get('/food-intake');
  return data;
}

export async function setWaterIntake(body) {
  const { data } = await userAxios.post('/water-intake', body);
  return data;
}

export async function setFoodIntake(body) {
  const { data } = await userAxios.post('/food-intake', body);
  return data;
}
export async function getMonthStatistic(body) {
  const { data } = await userAxios.get(`/statistics?date=${body}`);

  return data;
}
export async function getYearStatistic(body) {
  const { data } = await userAxios.get(`/statistics-year?date=${body}`);

  return data;
}
export async function updateFoodApi(id, body) {
  const { data } = await userAxios.put(`/food-intake/${id}`, body);
  return data;
}
