import * as yup from 'yup';

export const foodName = yup
  .string()
  .min('Must be 2 characters and more')
  .max('Must be 32 characters and less')
  .required('Required value');
export const carbonohidrates = yup
  .number('Must be a number')
  .min(0, 'Must be from 0 to 1500')
  .max(1500, 'Must be from 0 to 1500')
  .required('Required value');
export const protein = yup
  .number('Must be a number')
  .min(0, 'Must be from 0 to 1500')
  .max(1500, 'Must be from 0 to 1500')
  .required('Required value');
export const fat = yup
  .number('Must be a number')
  .min(0, 'Must be from 0 to 1500')
  .max(1500, 'Must be from 0 to 1500')
  .required('Required value');
