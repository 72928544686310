import * as yup from 'yup';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Breakfast from '../../../assets/img/mobile/Breakfast.png';
import Dinner from '../../../assets/img/mobile/Dinner.png';
import FieldErrorHandler from 'components/FieldErrorHandler/FieldErrorHandler';
import Lunch from '../../../assets/img/mobile/Lunch.png';
import Modal from 'react-modal';
import Snack from '../../../assets/img/mobile/Snack.png';
import a from '../../../animations/animations.module.css';
import clsx from 'clsx';
import css from './UpdateMealModal.module.css';
import { makeSelectMeal } from 'redux/user/userSelectors';
import { mealValidationRules } from 'helpers/validation';
import { updateUserFoodOperation } from 'redux/user/userOperations';
import { useFormik } from 'formik';

const { carbonohidrates, fat, protein } = mealValidationRules;

export const customStyles = {
  overlay: {
    background: 'rgba(5, 5, 5, 0.8)',
    overflow: 'scroll',
  },
};
const imageObject = { Breakfast, Lunch, Dinner, Snack };

const validationSchema = yup.object().shape({
  carbonohidrates,
  protein,
  fat,
});

const UpdateMealModal = ({
  updateMealModalOpen,
  setUpdateMealModalOpen,
  selectedMeal,
  foodName,
}) => {
  const dispatch = useDispatch();
  const selectMeal = makeSelectMeal(selectedMeal);
  const mealData = useSelector(state => selectMeal(state, selectedMeal));
  const editMeal = mealData?.filter(el => el.foodName === foodName);

  const [validationText, setValidationText] = useState(false);
  const formik = useFormik({
    initialValues: {
      foodName,
      carbonohidrates: editMeal[0]?.carbonohidrates || '',
      protein: editMeal[0]?.protein || '',
      fat: editMeal[0]?.fat || '',
    },
    validationSchema,
    onSubmit: values => {
      if (
        values.carbonohidrates === '' ||
        values.protein === '' ||
        values.fat === '' ||
        (!Number(values.carbonohidrates) &&
          !Number(values.protein) &&
          !Number(values.fat))
      ) {
        setValidationText(true);
        const timeoutId = setTimeout(() => {
          setValidationText(false);
          clearTimeout(timeoutId);
        }, 1000);
        return;
      }

      const foodSection = selectedMeal.toLowerCase();

      const updateFood = {
        [foodSection]: {
          foodName,
          carbonohidrates: String(values.carbonohidrates),
          protein: String(values.protein),
          fat: String(values.fat),
        },
      };

      dispatch(updateUserFoodOperation(updateFood));
      handleCloseModal();
    },
  });

  const { values, errors, touched, handleChange, handleSubmit, setValues } =
    formik;

  useEffect(() => {
    if (updateMealModalOpen) {
      if (editMeal[0]) {
        setValues({
          foodName,
          carbonohidrates: editMeal[0].carbonohidrates,
          protein: editMeal[0].protein,
          fat: editMeal[0].fat,
        });
      } else {
        setValues({
          foodName,
          carbonohidrates: '',
          protein: '',
          fat: '',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMealModalOpen, foodName]);

  const handleCloseModal = () => {
    setUpdateMealModalOpen(false);
    document.body.style.overflow = 'auto';

    setValidationText(false);
  };

  const handleDefault = e => {
    if (e.key === '-' || e.key === '+') {
      e.preventDefault();
    }
  };

  return (
    <Modal
      className={clsx(css.recordMealModal, a.scaleInCenter)}
      isOpen={updateMealModalOpen}
      onRequestClose={handleCloseModal}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <h3 className={css.recordMealModalTitle}>Edit your meal</h3>
      <div className={css.recordMealModalMealImageContainer}>
        <img
          width="32px"
          height="32px"
          src={imageObject[selectedMeal]}
          alt="meal"
        />
        <p className={css.recordMealModalMeal}>{selectedMeal}</p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={css.updateFoodForm}>
          <h4 className={css.textBtn}>{foodName}</h4>
          <div className={css.inputWrapper}>
            <label className={css.label}>
              <input
                placeholder="Carbonoh."
                type="number"
                name="carbonohidrates"
                className={css.recordMealModalInput}
                value={values.carbonohidrates}
                onChange={handleChange}
                onKeyDown={handleDefault}
              />
              <FieldErrorHandler
                errorMessage={errors.carbonohidrates}
                touched={touched.carbonohidrates}
                className={css.errorField}
              />
            </label>
            <label className={css.label}>
              <input
                placeholder="Protein"
                type="number"
                name="protein"
                className={css.recordMealModalInput}
                value={values.protein}
                onChange={handleChange}
                onKeyDown={handleDefault}
              />
              <FieldErrorHandler
                errorMessage={errors.protein}
                touched={touched.protein}
                className={css.errorField}
              />
            </label>
            <label className={css.label}>
              <input
                placeholder="Fat"
                name="fat"
                type="number"
                className={css.recordMealModalInput}
                value={values.fat}
                onChange={handleChange}
                onKeyDown={handleDefault}
              />
              <FieldErrorHandler
                errorMessage={errors.fat}
                touched={touched.fat}
                className={css.errorField}
              />
            </label>
          </div>
        </div>
        {validationText && (
          <p className={css.validationText}>
            Some one field must be more than 0
          </p>
        )}
        <div className={css.recordMealModalBtnContainer}>
          <button
            className={`${css.recordMealModalConfirmBtn} ${a.hoverYellowBtn}`}
            type="submit"
          >
            Confirm
          </button>
          <button
            className={`${css.recordMealModalCancelBtn} ${a.hoverCloseBtn}`}
            type="button"
            onClick={() => {
              setUpdateMealModalOpen(false);
              setValidationText(false);
              document.body.style.overflow = 'auto';
            }}
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default UpdateMealModal;
