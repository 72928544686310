import clsx from 'clsx';
import styles from './FieldErrorHandler.module.css';

const FieldErrorHandler = ({ errorMessage, touched, className = null }) => {
  return errorMessage && touched ? (
    <div className={clsx(styles.errorFields, className && className)}>
      {errorMessage}
    </div>
  ) : null;
};

export default FieldErrorHandler;
