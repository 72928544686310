import * as yup from 'yup';

import React, { useEffect, useRef } from 'react';
import {
  authValidationRules,
  userParamsValidationRules,
} from 'helpers/validation';
import { useDispatch, useSelector } from 'react-redux';

import FieldErrorHandler from 'components/FieldErrorHandler/FieldErrorHandler';
import a from '../../animations/animations.module.css';
import desktopSettingsPicture from '../../assets/img/desktop/Setting.png';
import iconsSrc from '../../assets/icons/symbol-defs.svg';
import mobileSettingsPicture from '../../assets/img/mobile/Setting.png';
import s from './Settings.module.css';
import tabletSettingsPicture from '../../assets/img/tablet/Setting.png';
import { updateSettingsOperations } from 'redux/user/userOperations';
import { useFormik } from 'formik';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

const { ageValidationRule, heightValidationRule, weightValidationRule } =
  userParamsValidationRules;
const { nameValidationRule } = authValidationRules;

const validationSchema = yup.object().shape({
  name: nameValidationRule,
  age: ageValidationRule,
  height: heightValidationRule,
  weight: weightValidationRule,
});

const SettingsPage = () => {
  const user = useSelector(state => state.auth?.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const firstRenderRef = useRef(true);
  const navigateRef = useRef(navigate);

  const formik = useFormik({
    initialValues: {
      name: user.name,
      gender: user.gender,
      age: user.age,
      height: user.height,
      weight: user.weight,
      activity: user.activity,
      file: null,
      avatarUrl: user.avatarURL,
    },
    validationSchema,
    onSubmit: values => {
      const formData = new FormData();

      formData.name = values.name;
      formData.age = values.age;
      formData.gender = values.gender;
      formData.height = values.height;
      formData.weight = values.weight;
      formData.activity = values.activity;
      formData.avatar = values.file;

      dispatch(updateSettingsOperations(formData));
    },
  });

  const { values, errors, touched, handleChange, setFieldValue, handleSubmit } =
    formik;

  const handleFileChange = e => {
    const selectedFile = e.target.files[0];
    const tmpPath = URL.createObjectURL(selectedFile);
    setFieldValue('file', selectedFile);
    setFieldValue('avatarUrl', tmpPath);
  };
  const isMobile = useMediaQuery({ maxWidth: 833 });
  const isTablet = useMediaQuery({ minWidth: 834, maxWidth: 1439 });
  const isDesktop = useMediaQuery({ minWidth: 1440 });

  const handleDefault = e => {
    if (e.key === '-' || e.key === '+') {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }
    navigateRef.current('/');
  }, [user]);

  return (
    <div className={s.settingsWrapper}>
      <form className={s.mainSettingsForm} onSubmit={handleSubmit}>
        <div className={`${s.settingsBtnTablet} ${a.slideLeftToRight}`}>
          <h2 className={s.settingsTitle}>Profile setting</h2>
          {isTablet && (
            <div className={s.settingsBtnWrapper}>
              <button className={`${s.saveBtn} ${a.hoverYellowBtn}`}>
                Save
              </button>
              <button
                type="button"
                className={`${s.cancelBtn} ${a.hoverCloseBtn}`}
                onClick={() => navigate('/')}
              >
                Cancel
              </button>
            </div>
          )}
          {isDesktop && (
            <div className={s.settingsBtnWrapper}>
              <button className={`${s.saveBtn} ${a.hoverYellowBtn}`}>
                Save
              </button>
              <button
                type="button"
                className={`${s.cancelBtn} ${a.hoverCloseBtn}`}
                onClick={() => navigate('/')}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
        {isMobile && (
          <img
            className={`${s.imageSettings} ${a.slideLeftToRight}`}
            src={mobileSettingsPicture}
            alt="Settings"
          />
        )}
        {isTablet && (
          <img
            className={`${s.imageSettings} ${a.slideLeftToRight}`}
            src={tabletSettingsPicture}
            alt="Settings"
          />
        )}
        <div className={s.settingsContent}>
          {isDesktop && (
            <img
              className={`${s.imageSettings} ${a.slideLeftToRight}`}
              src={desktopSettingsPicture}
              alt="Settings"
            />
          )}
          <div className={`${s.settingsForm} ${a.slideRightToLeft}`}>
            <label className={s.labelWrapper}>
              <span className={s.nameTitle}>Your name</span>
              <input
                className={s.nameInput}
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
              />
              <FieldErrorHandler
                errorMessage={errors.name}
                touched={touched.name}
              />
            </label>
            <label className={s.photoLabel}>
              <p className={s.nameTitle}>Your photo</p>
              <div className={s.photoLabelBox}>
                <img
                  src={values.avatarUrl}
                  alt="user avatar"
                  className={s.settingsAvatar}
                />
                <div className={s.photoInfoBox}>
                  <svg width="16" height="16px">
                    <use href={`${iconsSrc}#direct-inbox`} />
                  </svg>

                  <span>{values.file?.name || 'Download new photo'}</span>
                  <input
                    type="file"
                    name="avatar"
                    onChange={handleFileChange}
                    placeholder="Download new photo"
                    style={{ display: 'none' }}
                  />
                </div>
              </div>
            </label>
            <label className={s.labelWrapper}>
              <p className={s.nameTitle}>Your age</p>
              <input
                className={s.nameInput}
                type="text"
                name="age"
                placeholder="Enter your age"
                value={values.age}
                onKeyDown={handleDefault}
                onChange={handleChange}
                autoComplete="off"
              />
              <FieldErrorHandler
                errorMessage={errors.age}
                touched={touched.age}
              />
            </label>
            <div className={s.genderLabel}>
              <p className={s.nameTitle}>Gender</p>
              <div className={s.customRadioBox}>
                <label className={s.customRadio}>
                  <input
                    className={s.radioInput}
                    type="radio"
                    name="gender"
                    value="male"
                    checked={values.gender === 'male'}
                    onChange={handleChange}
                  />
                  <span className={s.goalList}>Male</span>
                </label>
                <label className={s.customRadio}>
                  <input
                    className={s.radioInput}
                    type="radio"
                    name="gender"
                    value="female"
                    checked={values.gender === 'female'}
                    onChange={handleChange}
                  />
                  <span className={s.goalList}>Female</span>
                </label>
              </div>
            </div>
            <label className={s.labelWrapper}>
              <p className={s.nameTitle}>Height</p>
              <input
                className={s.nameInput}
                type="text"
                name="height"
                value={values.height}
                onChange={handleChange}
                onKeyDown={handleDefault}
                autoComplete="off"
                placeholder="Enter your height in cm"
              />
              <FieldErrorHandler
                errorMessage={errors.height}
                touched={touched.height}
              />
            </label>
            <label className={s.labelWrapper}>
              <p className={s.nameTitle}>Weight</p>
              <input
                className={s.nameInput}
                type="text"
                name="weight"
                onKeyDown={handleDefault}
                value={values.weight}
                onChange={handleChange}
                autoComplete="off"
                placeholder="Enter your weight"
                step={0.1}
              />
              <FieldErrorHandler
                errorMessage={errors.weight}
                touched={touched.weight}
              />
            </label>
            <div className={`${s.activityWrapper} ${s.fullWidth}`}>
              <p className={s.nameTitle}>Your activity</p>
              <div className={s.activityContent}>
                <label className={s.actvityLabel}>
                  <input
                    className={s.radioInput}
                    type="radio"
                    name="activity"
                    value="1.2"
                    checked={values.activity === '1.2'}
                    onChange={handleChange}
                  />
                  <span>
                    1.2 - if you do not have physical activity and sedentary
                    work
                  </span>
                </label>
                <label className={s.actvityLabel}>
                  <input
                    className={s.radioInput}
                    type="radio"
                    name="activity"
                    value="1.375"
                    checked={values.activity === '1.375'}
                    onChange={handleChange}
                  />
                  <span>
                    1.375 - if you do short runs or light gymnastics 1-3 times a
                    week
                  </span>
                </label>
                <label className={s.actvityLabel}>
                  <input
                    className={s.radioInput}
                    type="radio"
                    name="activity"
                    value="1.55"
                    checked={values.activity === '1.55'}
                    onChange={handleChange}
                  />
                  <span>
                    1.55 - if you play sports with average loads 3-5 times a
                    week
                  </span>
                </label>
                <label className={s.actvityLabel}>
                  <input
                    className={s.radioInput}
                    type="radio"
                    name="activity"
                    value="1.725"
                    checked={values.activity === '1.725'}
                    onChange={handleChange}
                  />
                  <span>1.725 ​​- if you train fully 6-7 times a week</span>
                </label>
                <label className={s.actvityLabel}>
                  <input
                    className={s.radioInput}
                    type="radio"
                    name="activity"
                    value="1.9"
                    checked={values.activity === '1.9'}
                    onChange={handleChange}
                  />
                  <span>
                    1.9 - if your work is related to physical labor, you train 2
                    times a day and include strength exercises in your training
                    program
                  </span>
                </label>
              </div>
            </div>
            {isMobile && (
              <div className={s.settingsBtnWrapper}>
                <button className={`${s.saveBtn} ${a.hoverYellowBtn}`}>
                  Save
                </button>
                <button
                  type="button"
                  className={`${s.cancelBtn} ${a.hoverCloseBtn}`}
                  onClick={() => navigate('/')}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default SettingsPage;
