import * as goalPicture from 'assets/icons/emoji';

import React, { useState } from 'react';

import TargetSelectionModal from 'components/Modal/TargetSelectionModal/TargetSelectionModal';
import Waigth from '../../assets/icons/emoji/Waight.png';
import iconsSrc from '../../assets/icons/symbol-defs.svg';
import { imagesByGoalAndGender } from 'helpers/setTargetLogoByGender';
import s from './ControlPanel.module.css';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import СurrentWeightModal from '../Modal/СurrentWeightModal/СurrentWeightModal';

const ControlPanel = () => {
  const [targetModalOpen, setTargetModalOpen] = useState(false);

  const [weightModalOpen, setWeightModalOpen] = useState(false);
  const isMobileScreen = useMediaQuery({ maxWidth: 834 });
  const weight = useSelector(state => state?.auth?.user?.weight) || 0;
  const userGender = useSelector(state => state?.auth?.user?.gender);
  const goal = useSelector(state => state?.auth?.user?.goal);

  return (
    <>
      <TargetSelectionModal
        targetModalOpen={targetModalOpen}
        setTargetModalOpen={setTargetModalOpen}
        goal={goal}
      />
      <СurrentWeightModal
        weightModalOpen={weightModalOpen}
        setWeightModalOpen={setWeightModalOpen}
        selectedWeight={weight}
      />
      <div className={s.controlPanel}>
        <button onClick={() => setTargetModalOpen(true)}>
          <div className={s.targerSelect}>
            <div className={s.imageWrapper}>
              <img
                src={imagesByGoalAndGender(userGender, goal, goalPicture)}
                alt=""
                className={s.targetImg}
              />
            </div>
            <div className={s.textWrapper}>
              <div className={s.textContent}>
                <h3 className={s.titleTarget}>Goal</h3>
                <p className={s.subTitleTarget}>
                  {goal.charAt(0).toUpperCase() + goal.slice(1)}
                </p>
              </div>
              <svg
                style={
                  !targetModalOpen
                    ? { fill: 'white' }
                    : {
                        fill: 'white',
                        rotate: '180deg',
                      }
                }
                className={s.arrowIcon}
                width="14"
                height="14"
              >
                <use
                  href={
                    isMobileScreen
                      ? `${iconsSrc}#arrow-right`
                      : `${iconsSrc}#arrow-down`
                  }
                />
              </svg>
            </div>
          </div>
        </button>
        <button onClick={() => setWeightModalOpen(true)}>
          <div className={s.targerSelect}>
            <div className={s.imageWrapper}>
              <img src={Waigth} alt="" className={s.targetImg} />
            </div>
            <div className={s.textWrapper}>
              <div className={s.textContent}>
                <h3 className={s.titleTarget}>Weigth</h3>
                <p className={s.weightWrapper}>
                  <span className={s.subTitleTarget}>{weight}</span>
                  <span className={s.weigthTargetText}>kg</span>
                </p>
              </div>
              <svg className={s.recordWeightIcon} width="24" height="14">
                <use xlinkHref={`${iconsSrc}#pencil`} />
              </svg>
            </div>
          </div>
        </button>
      </div>
    </>
  );
};

export default ControlPanel;
