import { logout } from 'redux/auth/authSlice';

export const authorizationErrorHandler = error => dispatch => {
  if (error.response?.status === 401) {
    const id = setTimeout(() => {
      dispatch(logout());
      clearTimeout(id);
    }, 0);
  }
};
