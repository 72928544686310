import { setHeadersToken, unSetHeadersToken } from 'redux/user/userApi';

import { BASE_URL } from 'constants/apiConstants';
import axios from 'axios';

const authAxios = axios.create({
  baseURL: BASE_URL + '/api/auth',
});
export const setAuthInstanceToken = token =>
  (authAxios.defaults.headers.common.Authorization = 'Bearer ' + token);
const unsetAuthInstanceToken = () => {
  const token = authAxios.defaults.headers.common.Authorization;
  authAxios.defaults.headers.common.Authorization = '';
  return token;
};

// Функция для регистрации
export const signUpAPI = async userData => {
  const { data } = await authAxios.post('/signup', userData);
  setAuthInstanceToken(data.user.token);
  // setHeadersToken(data.user.token);
  return data;
};

// Функция для входа
export const signInAPI = async userData => {
  const { data } = await authAxios.post('/login', userData);
  setHeadersToken(data.user.token);
  return data;
};

// Функция для восстановления пароля
export const forgotPasswordAPI = async email => {
  const { data } = await authAxios.post('/forgot-password', { email });
  return data;
};

export const addGoals = async body => {
  const { data } = await authAxios.post('/requirements', body);
  const token = unsetAuthInstanceToken();
  setHeadersToken(token);
  return data;
};
// Функция для выхода
export const logoutAPI = async () => {
  const { data } = await authAxios.delete('/logout');
  unSetHeadersToken();
  return data;
};
