import * as yup from 'yup';

export const heightValidationRule = yup
  .number()
  .typeError('Height must be a number')
  .min(1, 'Height must be at least 1')
  .max(300, 'Height cannot be more than 300')
  .required('Height is a required field');
export const weightValidationRule = yup
  .number()
  .typeError('Weight must be a number')
  .min(1, 'Weight must be at least 1')
  .max(500, 'Weight cannot be more than 500')
  .required('Weight is a required field');
export const ageValidationRule = yup
  .number()
  .typeError('Age must be a number')
  .integer('Age must be an integer')
  .min(12, 'Age must be at least 12')
  .max(150, 'Age cannot be more than 150')
  .required('Age is a required field');
