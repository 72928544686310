import * as yup from 'yup';

export const regexPatterns = {
  email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
  password: /^(?=.*\d.*\d.*\d.*\d.*)(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d].{6,}$/,
};

export const nameValidationRule = yup
  .string()
  .min(2, 'Min 2 characters')
  .max(32, 'Max 32 characters')
  .required('Name is a required field');
export const emailValidationRule = yup
  .string()
  .email('Enter a valid email')
  .required('E-mail is a required field')
  .test('valid-email', 'Enter a valid email*', function (value) {
    return regexPatterns.email.test(value);
  });

export const passwordValidationRule = yup
  .string()
  // .matches(regexPatterns.password, {
  //   message: 'Please create a stronger password',
  // })
  .min(6, 'Must be 6 and more')
  .max(32, 'Must be 32 and less')
  .required('Password is a required field');
